/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import './courseOverview.scss'
import { ShowMyChapters, ShowMyCoursePageLessons } from '../ShowMyChapters'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { RWebShare } from 'react-web-share'
import SplashScreen from '../../Pages/SplashScreen'
import { callApi } from '../../utils/api-utils'
import { getCookie } from '../../utils/getCookie'

const defaults = {
  methods: {
    PATCH: {
      method: 'PATCH'
    },
    PUT: {
      method: 'PUT'
    },
    POST: {
      method: 'POST'
    }
  }
}

const allChapterEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/course/all-chapters'
}
const createOrderEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/Payment/orders'
}
const successOrderEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/Payment/success'
}

function CourseOverview(props) {
  const selectedOption = getCookie('selectedOption')

  const { res, checkedBuyCourse } = props
  const token = localStorage.getItem('accessToken')
  const referralID = localStorage.getItem('referral_code')
  const mongoId = localStorage.getItem('mongoId')
  const currentUserInformation = localStorage.getItem('profileInformation')
  let config = {
    headers: {
      Authorization: 'Bearer' + ' ' + token
    },
    params: {
      studentid: mongoId
    }
  }
  const [data] = useState(res)

  const currentUserInformationParse = JSON.parse(currentUserInformation)

  const [selectCategory, setSelectCategory] = useState(currentUserInformationParse?.student_select || null)

  const [studentName, setStudentName] = useState('')
  const [studentEmail, setStudentEmail] = useState('')
  const [studentContact, setStudentContact] = useState('')
  const [selectedCourse, setSelectedCourse] = useState('full_course')

  const [selectedChapter, setSelectedChapter] = useState(null)

  const [selectChapterData, setSelectChapterData] = useState(null)

  const [allChapter, setAllChapter] = useState([])

  const [couponCode, setCouponCode] = useState(null)
  const [discountPrice, setDiscountPrice] = useState(null)

  const [coinValue, setCoinValue] = useState(null)

  const [onChangeChapterViseData, setOnChangeChapterViseData] = useState(false)

  const [wait, setWait] = useState(false)

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  let { slug } = useParams()

  useEffect(() => {
    if (props.user) {
      getUserData()
    }
  }, [])

  async function getUserData() {
    const studentData = localStorage.getItem('userData')

    const parseStudentData = JSON.parse(studentData || null)

    setStudentName(parseStudentData?.personData?.legal_name || parseStudentData?.name)
    setStudentEmail(parseStudentData?.personData?.email || parseStudentData?.email)
    setStudentContact(parseStudentData?.personData?.phone || parseStudentData?.phone)
  }

  async function OpenRazorPay(
    pay,
    coursename,
    studentname,
    studentemail,
    studentcontact,
    userid,
    courseid,
    img,
    courseurl,
    mongoId,
    course_id,
    regular_price,
    chapter_id,
    chaptername,
    chapter_thumbnail
  ) {
    const userData = JSON.parse(localStorage.getItem('userData'))?.personData?.student_select

    const orderBody = {
      course_id: course_id,
      student_id: mongoId,
      coupon: couponCode || null,
      chapter_id: chapter_id || null,
      coin: Number(coinValue) || null
    }

    const body = {
      studentid: mongoId,
      studentname: studentname,
      studentemail: studentemail,
      courseid: course_id,
      coursename: coursename,
      image: img,
      status: 'success',
      slug: courseid,
      url: courseurl,
      category: userData === 'indian_students' ? 'indian' : 'international',
      currency: userData === 'indian_students' ? 'INR' : 'USD',
      amount: Number(pay),
      coin: coinValue,
      Date: new Date().toString(),
      chapterid: chapter_id || null,
      chaptername: chaptername || null,
      chapter_thumbnail: chapter_thumbnail || null,
      is_website: true
    }

    const finalObj = { ...body, ...orderBody }

    await callApi({ uriEndPoint: createOrderEndPoint, body: finalObj })
      .then(response => {
        console.log('response :', response)
        try {
          const { addOrder, order } = response

          const { amount, id: order_id, currency } = order

          const { _id: add_order_id } = addOrder

          const test = process.env.REACT_APP_RAZOR_PAY_KEY

          var options = {
            key: test, // Enter the Key ID generated from the Dashboard
            amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: currency,
            name: 'E-Learning @ SHAH',
            description: `For buying this ${coursename} course`,
            order_id: order_id,
            handler: async response => {
              if (response?.razorpay_payment_id) {
                setWait(true)
              }

              const data = {
                orderCreationId: order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
                add_order_id: add_order_id
              }

              await callApi({ uriEndPoint: successOrderEndPoint, body: data })
                .then(async () => {
                  const orderBody = {
                    studentid: mongoId,
                    studentname: studentname,
                    studentemail: studentemail,
                    courseid: course_id,
                    coursename: coursename,
                    image: img,
                    status: 'success',
                    slug: courseid,
                    url: courseurl,
                    category: userData === 'indian_students' ? 'indian' : 'international',
                    currency: userData === 'indian_students' ? 'INR' : 'USD',
                    amount: Number(pay),
                    coin: coinValue,
                    Date: new Date().toString(),
                    chapterid: chapter_id || null,
                    chaptername: chaptername || null,
                    chapter_thumbnail: chapter_thumbnail || null,
                    order_id: add_order_id
                  }

                  await axios
                    .post('/api/order/update-order-status', orderBody, {
                      headers: {
                        Authorization: 'Bearer ' + token // Note the space after 'Bearer'
                      }
                    })
                    .then(async () => {
                      // const stringifyDataBuyCourse = JSON.stringify(data.data.data)
                      // await axios.get('/api/order/buy-course-list', config).then(async data => {
                      // const stringifyDataBuyCourse = JSON.stringify(data.data.data)

                      const Data = {
                        email: studentemail,
                        course_name: coursename,
                        person: 1,
                        fees: regular_price,
                        total: regular_price,
                        price: regular_price,
                        chaptername: chaptername || null,
                        discount:
                          userData === 'indian_students'
                            ? Number(discountPrice?.data?.INRPrice).toFixed(0) || 0
                            : Number(discountPrice?.data?.DollarPrice).toFixed(0) || 0,
                        final_price:
                          userData === 'indian_students'
                            ? discountPrice?.data?.INRPrice
                              ? pay
                              : regular_price
                            : discountPrice?.data?.DollarPrice
                            ? pay.toFixed(0)
                            : regular_price,
                        buy_course: true
                      }
                      await axios.post(`/api/webinar/addWebinar`, Data).then(async () => {
                        // window.location.reload()
                      })

                      // await localStorage.setItem('buyCourses', stringifyDataBuyCourse)
                      window.location.href = '/purchased-course'
                      // })

                      // await localStorage.setItem('buyCourses', stringifyDataBuyCourse)
                    })
                    .catch(err => console.log('api error', err))
                })
                .catch(e => console.log(e))
            },

            prefill: {
              name: `${studentname}`,
              email: `${studentemail}`,
              contact: `${studentcontact}`
            },
            notes: {
              address: 'Samuel Hahnemann Academy for Homeopathy - SHAH, Anand - 388001 , Gujarat, India'
            },
            theme: {
              color: '#FF5722'
            }
          }

          const rzp1 = new window.Razorpay(options)

          rzp1.open()
        } catch (error) {
          console.error('Error initializing Razorpay:', error)
        }
      })
      .catch(e => console.log(e))
  }

  function PayButton(payment, paymentD, paymentH, coursename, courseid, img, courseurl, data) {
    // const fireid = localStorage.getItem('userId')
    const fireid = localStorage.getItem('mongoId')
    const userData = JSON.parse(localStorage.getItem('userData'))?.personData?.student_select

    const course_id = data._id
    const chapter_id = selectChapterData?._id
    const chapter_thumbnail = selectChapterData?.chapter_thumbnail
    const chaptername = selectChapterData?.chapterName

    if (selectedCourse === 'chapter_vise' && !selectedChapter) {
      alert('Select Chapter')
    } else {
      if (userData === 'indian_students') {
        OpenRazorPay(
          payment,
          coursename,
          studentName,
          studentEmail,
          studentContact,
          fireid,
          courseid,
          img,
          courseurl,
          mongoId,
          course_id,
          data.SellingPrice,
          chapter_id,
          chaptername,
          chapter_thumbnail
        )
      }
      if (userData === 'international_homeopathic_students') {
        OpenRazorPay(
          paymentD,
          coursename,
          studentName,
          studentEmail,
          studentContact,
          fireid,
          courseid,
          img,
          courseurl,
          mongoId,
          course_id,
          data.DollarPrice,
          chapter_id,
          chaptername,
          chapter_thumbnail
        )
      }
      if (userData === 'indian_homeopathic_practitioners') {
        OpenRazorPay(
          paymentH,
          coursename,
          studentName,
          studentEmail,
          studentContact,
          fireid,
          courseid,
          img,
          courseurl,
          mongoId,
          course_id,
          data.HomeopathicPrice,
          chapter_id,
          chaptername,
          chapter_thumbnail
          // mongoId
        )
      }
    }
  }

  const DiscountApi = async () => {
    if (couponCode) {
      await axios
        .post('/api/payment/check-coupon', {
          body: 'front-end-api',
          courseId: data._id,
          coupon: couponCode,
          coin: Number(coinValue) || null
        })
        .then(data => {
          setDiscountPrice(data.data)
        })
        .catch(err => console.log('error', err))
    }
  }

  const allChapterApi = async () => {
    try {
      await callApi({
        uriEndPoint: allChapterEndPoint,
        body: { id: props.res._id, body: 'front-end-api' }
      }).then(data => {
        setAllChapter(data)
      })
    } catch (error) {
      console.log(`🚀  ~ file: index.js:461 ~ allChapter ~ error:`, error)
    }
  }

  const selectChapterOnChange = async value => {
    if (mongoId) {
      try {
        const response = await axios.get('/api/order/buy-course-list', config)
        const buyCoursesData = response.data.data
        const filterData = buyCoursesData.filter(i => i.slug === slug)
        let checkValue = false
        filterData?.map(item => {
          if (item?.chapterid === value) {
            checkValue = true
          }
        })
        setOnChangeChapterViseData(checkValue)

        console.log(`🚀  ~ getData ~ filterData:`, filterData)
      } catch (error) {
        console.error('Error fetching buy course list:', error)
      }
    }

    const filterData = allChapter?.filter(i => i._id === value)[0] || null
    setSelectChapterData(filterData)
  }

  useEffect(() => {
    if (selectedCourse === 'chapter_vise') {
      allChapterApi()
    }
  }, [selectedCourse])

  const checkPrice = async () => {
    if (coinValue && selectCategory) {
      await axios
        .post('/api/payment/check-coupon', {
          body: 'front-end-api',
          courseId: data._id,
          coupon: couponCode || null,
          coin: Number(coinValue) || null
        })
        .then(data => {
          setDiscountPrice(data.data)
        })
        .catch(err => console.log('error', err))
    }
  }

  if (wait) {
    return <SplashScreen />
  } else {
    return (
      <>
        <div className='courseOverview'>
          <div className='main-box'>
            <div className='image-video-section'>
              <div className='image-container'>
                <div className='blur'></div>
                <img
                  src={
                    props.res.courseOverviewThumb
                      ? props.res.courseOverviewThumb
                      : 'https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784.jpg'
                  }
                  alt='course-overview'
                  loading='lazy'
                  sizes='(max-width: 1920px) 100vw, 1920px'
                />
              </div>
              <div className='play-button-main' onClick={props.onClick}>
                <div className='play-button-container'>
                  <div className='play-button'>
                    <i className='fa fa-play'></i>
                  </div>
                </div>
                <div className='text'>Course Overview</div>
              </div>
            </div>

            {!checkedBuyCourse && (
              <>
                {
                  <div className='option-section' style={{ backgroundColor: 'orangered', padding: '0px 1rem' }}>
                    <select
                      className='select-course-contain'
                      onChange={e => {
                        setSelectedCourse(e.target.value)
                        setAllChapter([])
                        setSelectedChapter(null)
                        setSelectChapterData(null)
                        setDiscountPrice(null)
                      }}
                      value={selectedCourse}
                      style={{
                        fontWeight: 'bolder',
                        backgroundColor: 'orangered',
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '17px'
                      }}
                    >
                      <option value='full_course'>BUY FULL COURSE</option>
                      <option value='chapter_vise'>BUY THE CHAPTER</option>
                    </select>
                  </div>
                }

                {selectedCourse !== 'chapter_vise' && selectedOption !== 'INTERNATIONAL' && (
                  <div
                    style={{
                      color: 'orangered',
                      fontWeight: 'bolder',
                      textAlign: 'center',
                      border: '2px solid orangered',
                      padding: '10px 5px',
                      marginTop: '15px'
                    }}
                  >
                    {'Please Click on your category'.toUpperCase()}
                  </div>
                )}
                {selectedCourse === 'chapter_vise' && (
                  <div className='option-section '>
                    <select
                      onChange={e => {
                        setSelectedChapter(e.target.value)
                        selectChapterOnChange(e.target.value)
                      }}
                      value={selectedChapter}
                    >
                      <option value='' key='' style={{ fontWeight: 'bolder' }}>
                        SELECT A CHAPTER
                      </option>
                      {allChapter.map(i => {
                        return (
                          <option value={i._id} key={i._id}>
                            {i.chapterName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                )}

                {!onChangeChapterViseData && (
                  <>
                    {currentUserInformationParse ? (
                      currentUserInformationParse?.student_select === 'indian_students' &&
                      selectedCourse === 'full_course' ? (
                        <div
                          className='students-price'
                          onClick={() => {
                            setSelectCategory('indian_students')
                          }}
                        >
                          <div
                            className='main-box'
                            style={{
                              border:
                                selectCategory === 'indian_students' ? '5px solid orangered' : '1px solid orangered',
                              cursor: 'pointer'
                            }}
                          >
                            <div className='header'>INDIAN STUDENTS (B.H.M.S. & M.D.)</div>

                            <hr />
                            <div className='price'>
                              ₹{selectChapterData ? selectChapterData.indian_student_selling_price : data.SellingPrice}
                              /-
                            </div>
                            <div className='regular-price'>
                              Regular Price:
                              <strike>
                                ₹
                                {selectChapterData ? selectChapterData.indian_student_regular_price : data.RegularPrice}
                                /-
                              </strike>
                            </div>
                            <div className='para'>
                              {selectChapterData
                                ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                                : '(Promoting Make in India & Digital India Programs of Government of India)'}
                            </div>
                          </div>
                        </div>
                      ) : (
                        selectCategory === 'indian_students' && (
                          <div
                            className='students-price'
                            onClick={() => {
                              setSelectCategory('indian_students')
                            }}
                          >
                            <div
                              className='main-box'
                              style={{
                                border:
                                  selectCategory === 'indian_students' ? '5px solid orangered' : '1px solid orangered',
                                cursor: 'pointer'
                              }}
                            >
                              <div className='header'>INDIAN STUDENTS (B.H.M.S. & M.D.)</div>

                              <hr />
                              <div className='price'>
                                ₹{selectChapterData ? selectChapterData.indian_student_selling_price : '-'}
                                /-
                              </div>
                              <div className='regular-price'>
                                Regular Price:
                                <strike>
                                  ₹{selectChapterData ? selectChapterData.indian_student_regular_price : '-'}
                                  /-
                                </strike>
                              </div>
                              <div className='para'>
                                {selectChapterData
                                  ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                                  : '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : selectedCourse === 'full_course' && selectedOption === 'BHARAT' ? (
                      <div
                        className='students-price'
                        onClick={() => {
                          setSelectCategory('indian_students')
                        }}
                      >
                        <div
                          className='main-box'
                          style={{
                            border:
                              selectCategory === 'indian_students' ? '5px solid orangered' : '1px solid orangered',
                            cursor: 'pointer'
                          }}
                        >
                          <div className='header'>INDIAN STUDENTS (B.H.M.S. & M.D.)</div>

                          <hr />
                          <div className='price'>
                            ₹{selectChapterData ? selectChapterData.indian_student_selling_price : data.SellingPrice}
                            /-
                          </div>
                          <div className='regular-price'>
                            Regular Price:
                            <strike>
                              ₹{selectChapterData ? selectChapterData.indian_student_regular_price : data.RegularPrice}
                              /-
                            </strike>
                          </div>
                          <div className='para'>
                            {selectChapterData
                              ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                              : '(Promoting Make in India & Digital India Programs of Government of India)'}
                          </div>
                        </div>
                      </div>
                    ) : (
                      selectedOption === 'BHARAT' && (
                        <div
                          className='students-price'
                          onClick={() => {
                            setSelectCategory('indian_students')
                          }}
                        >
                          <div
                            className='main-box'
                            style={{
                              border:
                                selectCategory === 'indian_students' ? '5px solid orangered' : '1px solid orangered',
                              cursor: 'pointer'
                            }}
                          >
                            <div className='header'>INDIAN STUDENTS AND HOMEOPATHS</div>

                            <hr />
                            <div className='price'>
                              ₹{selectChapterData ? selectChapterData.indian_student_selling_price : '-'}
                              /-
                            </div>
                            <div className='regular-price'>
                              Regular Price:
                              <strike>
                                ₹{selectChapterData ? selectChapterData.indian_student_regular_price : '-'}
                                /-
                              </strike>
                            </div>
                            <div className='para'>
                              {selectChapterData
                                ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                                : '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {currentUserInformationParse ? (
                      currentUserInformationParse?.student_select === 'indian_homeopathic_practitioners' &&
                      selectedCourse === 'full_course' ? (
                        <div
                          className='students-price'
                          onClick={() => {
                            setSelectCategory('indian_homeopathic_practitioners')
                          }}
                        >
                          <div
                            className='main-box'
                            style={{
                              border:
                                selectCategory === 'indian_homeopathic_practitioners'
                                  ? '5px solid orangered'
                                  : '1px solid orangered',
                              cursor: 'pointer'
                            }}
                          >
                            <div className='header'>INDIAN PRACTICING HOMEOPATHS</div>
                            <hr />
                            <div className='price'>
                              ₹
                              {selectChapterData
                                ? selectChapterData.indian_student_selling_price
                                : data.HomeopathicSellingPrice}
                              /-
                            </div>
                            <div className='regular-price'>
                              Regular Price:
                              <strike>
                                ₹
                                {selectChapterData
                                  ? selectChapterData.indian_student_regular_price
                                  : data.HomeopathicRegularPrice}
                                /-
                              </strike>
                            </div>
                            <div className='para'>
                              {selectChapterData
                                ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                                : "(Conforming Prime Minister of India's Vision - Vocal for Local and Local toGlobal)"}
                            </div>
                          </div>
                        </div>
                      ) : (
                        currentUserInformationParse?.student_select === 'indian_homeopathic_practitioners' && (
                          <div
                            className='students-price'
                            onClick={() => {
                              setSelectCategory('indian_homeopathic_practitioners')
                            }}
                          >
                            <div
                              className='main-box'
                              style={{
                                border:
                                  selectCategory === 'indian_homeopathic_practitioners'
                                    ? '5px solid orangered'
                                    : '1px solid orangered',
                                cursor: 'pointer'
                              }}
                            >
                              <div className='header'>INDIAN PRACTICING HOMEOPATHS</div>
                              <hr />
                              <div className='price'>
                                ₹{selectChapterData ? selectChapterData.indian_student_selling_price : '-'}
                                /-
                              </div>
                              <div className='regular-price'>
                                Regular Price:
                                <strike>
                                  ₹{selectChapterData ? selectChapterData.indian_student_regular_price : '-'}
                                  /-
                                </strike>
                              </div>
                              <div className='para'>
                                {selectChapterData
                                  ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                                  : '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : selectedCourse === 'full_course' && selectedOption === 'BHARAT' ? (
                      <div
                        className='students-price'
                        onClick={() => {
                          setSelectCategory('indian_homeopathic_practitioners')
                        }}
                      >
                        <div
                          className='main-box'
                          style={{
                            border:
                              selectCategory === 'indian_homeopathic_practitioners'
                                ? '5px solid orangered'
                                : '1px solid orangered',
                            cursor: 'pointer'
                          }}
                        >
                          <div className='header'>INDIAN PRACTICING HOMEOPATHS</div>
                          <hr />
                          <div className='price'>
                            ₹
                            {selectChapterData
                              ? selectChapterData.indian_student_selling_price
                              : data.HomeopathicSellingPrice}
                            /-
                          </div>
                          <div className='regular-price'>
                            Regular Price:
                            <strike>
                              ₹
                              {selectChapterData
                                ? selectChapterData.indian_student_regular_price
                                : data.HomeopathicRegularPrice}
                              /-
                            </strike>
                          </div>
                          <div className='para'>
                            {selectChapterData
                              ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                              : "(Conforming Prime Minister of India's Vision - Vocal for Local and Local toGlobal)"}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {currentUserInformationParse ? (
                      currentUserInformationParse?.student_select === 'international_homeopathic_students' &&
                      selectedCourse === 'full_course' ? (
                        <div
                          className='students-price'
                          onClick={() => {
                            setSelectCategory('international_homeopathic_students')
                          }}
                        >
                          <div
                            className='main-box'
                            style={{
                              border:
                                selectCategory === 'international_homeopathic_students'
                                  ? '5px solid orangered'
                                  : '1px solid orangered',
                              cursor: 'pointer'
                            }}
                          >
                            <div className='header'> INTERNATIONAL STUDENTS & HOMEOPATHS (Worldwide)</div>
                            <hr />
                            <div className='price'>
                              $
                              {selectChapterData
                                ? selectChapterData.international_selling_price
                                : data.DollarSellingPrice}
                              /-
                            </div>
                            <div className='regular-price'>
                              Regular Price:
                              <strike>
                                $
                                {selectChapterData
                                  ? selectChapterData.international_regular_price
                                  : data.DollarRegularPrice}
                                /-
                              </strike>
                            </div>
                            <div className='para'>
                              {selectChapterData
                                ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                                : "(Conforming Prime Minister of India's Vision - Vocal for Local and Local toGlobal)"}
                            </div>
                          </div>
                        </div>
                      ) : (
                        currentUserInformationParse?.student_select === 'international_homeopathic_students' && (
                          <div
                            className='students-price'
                            onClick={() => {
                              setSelectCategory('international_homeopathic_students')
                            }}
                          >
                            <div
                              className='main-box'
                              style={{
                                border:
                                  selectCategory === 'international_homeopathic_students'
                                    ? '5px solid orangered'
                                    : '1px solid orangered',
                                cursor: 'pointer'
                              }}
                            >
                              <div className='header'> INTERNATIONAL STUDENTS & HOMEOPATHS (Worldwide)</div>
                              <hr />
                              <div className='price'>
                                ${selectChapterData ? selectChapterData.international_selling_price : '-'}
                                /-
                              </div>
                              <div className='regular-price'>
                                Regular Price:
                                <strike>
                                  ${selectChapterData ? selectChapterData.international_regular_price : '-'}
                                  /-
                                </strike>
                              </div>
                              <div className='para'>
                                {selectChapterData
                                  ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                                  : '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : selectedOption === 'INTERNATIONAL' && selectedCourse === 'full_course' ? (
                      <div
                        className='students-price'
                        onClick={() => {
                          setSelectCategory('international_homeopathic_students')
                        }}
                      >
                        <div
                          className='main-box'
                          style={{
                            border:
                              selectCategory === 'international_homeopathic_students'
                                ? '5px solid orangered'
                                : '1px solid orangered',
                            cursor: 'pointer'
                          }}
                        >
                          <div className='header'> INTERNATIONAL STUDENTS & HOMEOPATHS (Worldwide)</div>
                          <hr />
                          <div className='price'>
                            $
                            {selectChapterData
                              ? selectChapterData.international_selling_price
                              : data.DollarSellingPrice}
                            /-
                          </div>
                          <div className='regular-price'>
                            Regular Price:
                            <strike>
                              $
                              {selectChapterData
                                ? selectChapterData.international_regular_price
                                : data.DollarRegularPrice}
                              /-
                            </strike>
                          </div>
                          <div className='para'>
                            {selectChapterData
                              ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                              : "(Conforming Prime Minister of India's Vision - Vocal for Local and Local toGlobal)"}
                          </div>
                        </div>
                      </div>
                    ) : (
                      selectedOption === 'INTERNATIONAL' && (
                        <div
                          className='students-price'
                          onClick={() => {
                            setSelectCategory('international_homeopathic_students')
                          }}
                        >
                          <div
                            className='main-box'
                            style={{
                              border:
                                selectCategory === 'international_homeopathic_students'
                                  ? '5px solid orangered'
                                  : '1px solid orangered',
                              cursor: 'pointer'
                            }}
                          >
                            <div className='header'> INTERNATIONAL STUDENTS & HOMEOPATHS (Worldwide)</div>
                            <hr />
                            <div className='price'>
                              ${selectChapterData ? selectChapterData.international_selling_price : '-'}
                              /-
                            </div>
                            <div className='regular-price'>
                              Regular Price:
                              <strike>
                                ${selectChapterData ? selectChapterData.international_regular_price : '-'}
                                /-
                              </strike>
                            </div>
                            <div className='para'>
                              {selectChapterData
                                ? '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'
                                : '(No Certificate of Completion and the Badge of Honor from SHAH will be given)'}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {selectedCourse === 'full_course' && (
                      <>
                        <div style={{ width: '100%' }}>
                          <div
                            className='option-section'
                            style={{ display: 'flex', marginBottom: '10px', gap: '0.5rem', marginTop: '1rem' }}
                          >
                            <input
                              type='text'
                              placeholder='Apply "SHAH30"'
                              onChange={e => setCouponCode(e.target.value)}
                              style={{ border: '1px solid orangered', padding: '14px', fontSize: '16px' }}
                            />
                            <Button
                              variant='contained'
                              onClick={() => {
                                // if (mongoId) {
                                DiscountApi()
                                // } else {
                                //   handleOpen()
                                // }
                              }}
                              type='button'
                              style={{ fontSize: '12px', backgroundColor: 'orangered', color: 'white' }}
                            >
                              Check Coupon
                            </Button>
                          </div>

                          {discountPrice !== null && discountPrice?.statusCode !== 200 && (
                            <span style={{ color: 'orangered' }}>Not valid coupon</span>
                          )}

                          {currentUserInformationParse?.coin ? (
                            <>
                              <p
                                style={{
                                  color: 'orangered',
                                  border: '1px solid orangered',
                                  padding: '0.5rem 1rem',
                                  marginTop: '1rem'
                                }}
                              >
                                Your Points : {currentUserInformationParse?.coin}
                              </p>
                              <div className='option-section' style={{ display: 'flex', marginBottom: '10px' }}>
                                <input
                                  type='text'
                                  placeholder='Use Your Coin'
                                  value={coinValue}
                                  maxLength='2'
                                  onChange={e => setCoinValue(e.target.value)}
                                  onInput={e => {
                                    if (currentUserInformationParse?.coin) {
                                      if (Number(e.target.value) <= currentUserInformationParse?.coin) {
                                        e.target.value = Number(e.target.value)
                                      } else {
                                        e.target.value = currentUserInformationParse?.coin
                                      }
                                    }
                                  }}
                                  style={{
                                    border: '1px solid orangered',
                                    padding: '14px',
                                    fontSize: '16px'
                                  }}
                                />
                                <Button
                                  variant='contained'
                                  onClick={() => checkPrice()}
                                  style={{
                                    marginLeft: '10px',
                                    fontSize: '12px',
                                    backgroundColor: 'orangered',
                                    color: 'white',
                                    width: '100%'
                                  }}
                                >
                                  Check Price
                                </Button>
                              </div>
                              <span>Maximum point you will use 50</span>
                            </>
                          ) : null}
                          {discountPrice !== null && discountPrice?.data?.isValidCoin === false && (
                            <span style={{ color: 'orangered' }}>{'Not valid coin'}</span>
                          )}
                        </div>
                      </>
                    )}

                    {selectCategory === 'indian_students' && discountPrice !== null && (
                      <div className='students-price'>
                        <div className='main-box' style={{ border: '5px solid orangered' }}>
                          <div className='header'>Coupon Discount</div>
                          <hr />
                          <div className='price'>₹{discountPrice && discountPrice.data.INRPrice}/-</div>
                          <div className='regular-price'>
                            Discount :
                            <span>₹{(data.SellingPrice - Number(discountPrice.data?.INRPrice)).toFixed(2)}/-</span>
                          </div>
                          <div className='regular-price'>
                            Regular Price: <strike> ₹{data.SellingPrice}/-</strike>
                          </div>
                        </div>
                      </div>
                    )}
                    {selectCategory === 'international_homeopathic_students' && discountPrice !== null && (
                      <div className='students-price'>
                        <div className='main-box' style={{ border: '5px solid orangered' }}>
                          <div className='header'>Coupon Discount</div>
                          <hr />
                          <div className='price'>${discountPrice && discountPrice.data.DollarPrice}/-</div>
                          <div className='regular-price'>
                            Discount :
                            <span>
                              ${(data.DollarRegularPrice - Number(discountPrice?.data?.DollarPrice)).toFixed(2)}/-
                            </span>
                          </div>
                          <div className='regular-price'>
                            Regular Price:<strike>${data.DollarRegularPrice}/-</strike>
                          </div>
                        </div>
                      </div>
                    )}
                    {selectCategory === 'indian_homeopathic_practitioners' && discountPrice !== null && (
                      <div className='students-price'>
                        <div className='main-box' style={{ border: '5px solid orangered' }}>
                          <div className='header'>Coupon Discount</div>
                          <hr />
                          <div className='price'>₹{discountPrice && discountPrice.data.HomeopathicPrice}/-</div>
                          <div className='regular-price'>
                            Discount :
                            <span>
                              ₹
                              {(data.HomeopathicRegularPrice - Number(discountPrice?.data?.HomeopathicPrice)).toFixed(
                                2
                              )}
                              /-
                            </span>
                          </div>
                          <div className='regular-price'>
                            Regular Price:<strike>₹{data.HomeopathicRegularPrice}/-</strike>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {(checkedBuyCourse === null || checkedBuyCourse === false) && !onChangeChapterViseData && (
              <>
                <div className='buy-this-course-button'>
                  <button
                    onClick={() =>
                      props.user
                        ? PayButton(
                            discountPrice === null
                              ? selectChapterData
                                ? Number(selectChapterData?.indian_student_selling_price)
                                : data.SellingPrice
                              : parseFloat(discountPrice?.data.INRPrice),
                            discountPrice === null
                              ? selectChapterData
                                ? Number(selectChapterData?.international_selling_price)
                                : data.DollarSellingPrice
                              : parseFloat(discountPrice?.data.DollarPrice),
                            discountPrice === null
                              ? selectChapterData
                                ? Number(selectChapterData?.indian_homeopathic_selling_price)
                                : data.HomeopathicSellingPrice
                              : parseFloat(discountPrice?.data.HomeopathicPrice),
                            data.nameOfCourse,
                            data.slug,
                            data.Img,
                            window.location.href,
                            data
                          )
                        : (window.location.href = '/signup')
                    }
                  >
                    {selectedCourse !== 'chapter_vise' ? ' BUY THIS COURSE' : 'BUY THE CHAPTER'}
                  </button>
                </div>

                <h3 className='payment-box'>
                  {' '}
                  <i style={{ fontSize: 25 }} className='fa fa-cc-visa' aria-hidden='true'></i>{' '}
                  <i style={{ fontSize: 25 }} className='fa fa-paypal' aria-hidden='true'></i>{' '}
                  <i style={{ fontSize: 25 }} className='fa fa-cc-mastercard' aria-hidden='true'></i>{' '}
                  <i style={{ fontSize: 25 }} className='fa fa-google-wallet' aria-hidden='true'></i>{' '}
                  <i style={{ fontSize: 25 }} className='fa fa-cc-amex' aria-hidden='true'></i>{' '}
                  <i style={{ fontSize: 25 }} className='fa fa-cc-discover' aria-hidden='true'></i> <br />{' '}
                  <div style={{ fontSize: 12, color: 'orangered', marginTop: '1em' }}>
                    No Cost EMI options available upon full course purchase only.
                  </div>{' '}
                  <div style={{ fontSize: 12, color: 'orangered', marginTop: '1em' }}>
                    All the course prices are inclusive of taxes.
                  </div>{' '}
                </h3>
                {allChapter.length === 0 && (
                  <div className='course-features-section'>
                    <div className='header'>Course Features</div>
                    <div className='feature'>
                      <div className='question'>Chapters</div>
                      <div className='answer'>
                        {' '}
                        <ShowMyChapters id={data._id} />
                      </div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Lessons</div>
                      <div className='answer'>
                        {' '}
                        <ShowMyCoursePageLessons id={data._id} />
                      </div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Clinical Quiz</div>
                      <div className='answer'>{data.quizzes ? data.quizzes : 0}</div>
                    </div>
                    <hr />

                    {res.clinicalPoints !== 'No' && (
                      <div className='feature'>
                        <div className='question'>Keynotes from the Aphorisms</div>
                        <div className='answer'>{data.clinicalPoints}</div>
                      </div>
                    )}
                    {res.clinicalPoints !== 'No' && <hr />}

                    <div className='feature'>
                      <div className='question'>Course Certification</div>
                      <div className='answer'>Fellowship</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Final Exam</div>
                      <div
                        className='answer'
                        style={{ cursor: 'pointer', color: 'orangered' }}
                        onClick={() => (window.location.href = '/faqs#1415')}
                      >
                        {' '}
                        FAQ No.14 & 15{' '}
                      </div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Badge of Honour</div>
                      <div className='answer'> {data.BadgeHonor}</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Live with Dr. Krutik Shah</div>
                      <div className='answer'>{data.liveShah}</div>
                    </div>
                    <hr />
                    <div className='feature'>
                      <div className='question'>Buy the Course Chapterwise</div>
                      <div className='answer'>{data.WatchCourse}</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Course Access</div>
                      <div className='answer'> {data.CourseAccess}</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Download Course Notes</div>
                      <div className='answer'>{data.notes}</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>
                        Chat using{' '}
                        <b
                          style={{ fontWeight: 'bolder', color: 'orangered' }}
                          onClick={() => {
                            var userAgent = navigator.userAgent || navigator.vendor || window.opera

                            if (/windows phone/i.test(userAgent)) {
                              window.open('https://play.google.com/store/apps/details?id=com.shahapp', '_blank')
                              return
                            }

                            if (/android/i.test(userAgent)) {
                              window.open('https://play.google.com/store/apps/details?id=com.shahapp', '_blank')
                              return
                            }

                            // iOS detection from: http://stackoverflow.com/a/9039885/177710
                            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                              window.open('https://apps.apple.com/in/app/shah-courses/id6470359344', '_blank')

                              return
                            }
                          }}
                        >
                          SHAH Courses
                        </b>{' '}
                        App
                      </div>

                      <div className='answer'>{data.ChatwithCoach}</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Total Duration</div>
                      <div className='answer'>{data.TotalDuration}</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Internal Recorded Webinar</div>
                      <div className='answer'>Yes</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Rewards on early completion</div>
                      <div className='answer'>Yes</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Language</div>
                      <div className='answer'>{data.Language}</div>
                    </div>
                    <hr />

                    <div className='feature'>
                      <div className='question'>Skill</div>
                      <div className='answer'>{data.SkillLevel}</div>
                    </div>
                    <hr />
                  </div>
                )}
              </>
            )}
          </div>
          {referralID !== null ? (
            <div className='referral-code-main-div'>
              <div className='text-div'>
                <RWebShare
                  data={{
                    text: 'Yes I am proud to be a Homeopath',
                    url: `https://www.samuelhahnemann.org/signup?referral_code=${referralID}`,
                    title: 'samuelhahnemann'
                  }}
                  onClick={() => console.log('shared successfully!')}
                >
                  <button className='share-code'>Share this course & Win</button>
                </RWebShare>
                <span className='text'>Refer a course to your friends and get 15% in your wallet.</span>
              </div>
            </div>
          ) : (
            <div className='referral-code-main-div'>
              <div className='text-div' onClick={() => (window.location.href = '/signup')}>
                <button className='share-code'>Share this course & Win</button>

                <span className='text'>Refer a course to your friends and get 15% in your wallet.</span>
              </div>
            </div>
          )}

          {allChapter.length === 0 && (
            <div className='shah-benefits'>
              <h2>Benefits</h2>
              <ul>
                <div style={{ display: 'flex', alignItems: 'start' }}>
                  <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                  <li>Buy any Chapter without buying the full Organon Course!</li>
                </div>
                <div style={{ display: 'flex', alignItems: 'start' }}>
                  <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                  <li>
                    Chat with mentor and coach using <b style={{ color: 'orangered' }}>SHAH Courses</b> App to solve
                    your doubts instantly.
                  </li>
                </div>
                <div style={{ display: 'flex', alignItems: 'start' }}>
                  <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                  <li style={{ color: 'orangered' }}>
                    "Master Mind Call LIVE with Dr. Krutik Shah every fortnightly."{' '}
                  </li>
                </div>
                <div style={{ display: 'flex', alignItems: 'start' }}>
                  <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                  <li>Free access to Internal Recorded Webinars of the same group of courses.</li>
                </div>
                <div style={{ display: 'flex', alignItems: 'start' }}>
                  <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                  <li>Get Rewards on early Course Completion.</li>
                </div>

                <div style={{ display: 'flex', alignItems: 'start' }}>
                  <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>
                  <li>
                    You will receive course completion Certificate and Badge of Honour by courier at your doorstep
                    worldwide.
                  </li>
                </div>
                <div style={{ display: 'flex', alignItems: 'start' }}>
                  <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>
                  <li>100% Money Return in 5 days. No Question Asked</li>
                </div>
              </ul>
            </div>
          )}
        </div>
        <Modal open={open} onClose={handleClose}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              border: '2px solid #fff',

              borderRadius: 10,
              boxShadow: 24,
              padding: '1rem',
              backgroundColor: 'white'
            }}
          >
            <Typography
              id='modal-modal-title'
              variant='h6'
              component='h6'
              style={{ fontSize: '17px', textAlign: 'center' }}
            >
              Login is a mandatory to avail maximum discount in all the Organon Courses offered by SHAH
            </Typography>
            <div style={{ display: 'flex', gap: 15, justifyContent: 'center', marginTop: '15px' }}>
              <Button
                variant='contained'
                onClick={() => {
                  window.location.href = '/login'
                }}
              >
                <b>Login</b>
              </Button>
              <Button
                variant='contained'
                style={{ backgroundColor: 'orangered', color: 'white' }}
                onClick={handleClose}
              >
                <b>Cancel</b>
              </Button>
            </div>
          </Box>
        </Modal>
      </>
    )
  }
}

export default CourseOverview
